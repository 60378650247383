import React from 'react'
import "./Header.css"
import { useState } from 'react'
import Logo from '../../assests/images/Logo/elsevier-logo.svg'




const Header = () => {
    const navLink = [
        {
            id: 1,
            name: "Home",
            link:"https://www.elsevier.com/academic-and-government"
        },{
            id: 2,
            name:"Health",
            link:"https://www.elsevier.com/health"
        },{
            id: 3,
            name:"Industry",
            link:"https://www.elsevier.com/industry"
        },{
            id: 4,
            name:"Insight",
            link:"https://www.elsevier.com/connect"
        },{
            id: 5,
            name:"About",
            link:"https://www.elsevier.com/about"
        },{
            id: 6,
            name:"Support",
            link:"https://www.elsevier.com/support"
        }
    ]
    
    const [hamburger , sethamburger] = useState(true);
    function toggle(){
        sethamburger(!hamburger);
    }
  return (
    <header className='header h-[100px] w-full shadow-md overflow-hidden max-sm:h-[60px]'>
        <div className="headerContainer h-full w-11/12 mx-auto py-4 bg-white flex justify-between max-sm:w-full max-sm:py-0">
            <div className="headerlogo h-full w-fit flex items-center justify-center">
                <img className='s max-sm:scale-[0.6]' src={Logo} alt="" />
            </div>
            <div className="headerList flex gap-4 items-center justify-center">
                <div className={hamburger === true ? "listOne flex gap-4" : "listOne flex gap-4 menuOpen"}>
                    {
                        navLink.map((item) =>
                            <a className='text-xl tracking-wider' href={item.link}>{item.name}</a>
                        )
                    }
                    
                </div>
                <div className="listTwo px-6 py-4 bg-blue-800 rounded-xl text-white max-lg:hidden">
                    <a className='text-xl' href="https://www.elsevier.com/researcher/author/publish-with-us">Publish With us </a>
                </div>
                <div className="listThree flex gap-2">
                    {/* <i class="ri-search-line text-2xl"></i>
                    <i class="ri-global-line text-2xl"></i> */}
                    <div className='relative flex items-center justify-center px-4'>
                        <i class="ri-menu-line text-2xl sm:hidden"></i>
                        <input className='absolute h-full w-full opacity-0' onClick={toggle} type="checkbox" name="" id="" />
                    </div>
                </div>
            </div>
        </div>
    </header>  
    )
}

export default Header;