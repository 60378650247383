import React from 'react'
import logo from "../../assests/images/Logo/elsevier-logo.svg"

const Footer = () => {
  return (
    <footer className='footer h-full w-full bg-black py-4 max-sm:py-2'>
        
        <div className="foot1 h-full w-4/5 mx-auto flex max-sm:w-11/12">
            <div className="footLink1 flex-1 h-full w-fit py-6 max-sm:py-2">
                <h1 className='text-3xl text-white py-6 tracking-wide max-sm:text-lg'>Useful Links</h1>
                <div className="links text-blue-700 flex flex-col gap-2 max-sm:text-sm">
                    <a href="https://www.elsevier.com/researcher/author/submit-your-paper">Submit your paper</a>
                    <a href="https://shop.elsevier.com/">Shop Books</a>
                    <a href="https://www.elsevier.com/open-access">Open Access</a>
                    <a href="https://www.elsevier.com/products">View all Products</a>
                    <a href="https://www.elsevier.com/connect">Elseivier Connect</a>
                </div>
            </div>

            <div className="footLink2 flex-1 h-full w-fit py-6 max-sm:py-2">
                <h1 className='text-3xl text-white py-6 tracking-wide max-sm:text-lg'>Support</h1>
                <div className="links text-blue-700 flex flex-col gap-2 max-sm:text-sm">
                    <a href="https://service.elsevier.com/app/overview/elsevier/">Submit your paper</a>
                    <a href="https://www.elsevier.com/support">Customer Support</a>
                    <a href="https://www.relx.com/~/media/Files/R/RELX-Group/documents/responsibility/relx-slavery-statement-2024.pdf">Modern Slavery act statement</a>
                </div>
            </div>

            <div className="footLink3 flex-1 h-full w-fit py-6 max-sm:py-2">
                <h1 className='text-3xl text-white py-6 tracking-wide max-sm:text-lg'>About</h1>
                <div className="links text-blue-700 flex flex-col gap-2 max-sm:text-sm">
                    <a href="https://www.elsevier.com/about">About Elseiver</a>
                    <a href="https://www.elsevier.com/about/careers">Careers</a>
                    <a href="https://www.elsevier.com/about/global-press-office">Global Press office</a>
                    <a href="https://www.elsmediakits.com/?utm_source=elsevier.com&utm_medium=referral&dgcid=4168448395">Advertising, reprints & supplements</a>
                </div>
            </div>
            
        </div>

        <div className="foot2 h-full w-11/12 mx-auto border-b-[0.2px] py-4 flex justify-between text-white">
            <div className="foot2Left">
                <p className='text-2xl max-sm:text-xl'>India</p>
            </div>
            <div className="foot2Right text-3xl max-sm:text-xl flex gap-2">
                <a href="https://www.linkedin.com/company/elsevier/"><i class="cursor-pointer ri-linkedin-box-fill"></i></a>
                <a href="https://x.com/ElsevierConnect"><i class="cursor-pointer ri-twitter-x-fill"></i></a>
                <a href="https://www.facebook.com/ElsevierConnect"><i class="cursor-pointer ri-facebook-circle-fill"></i></a>
                <a href="https://www.youtube.com/c/elsevier"><i class="cursor-pointer ri-youtube-fill"></i></a>
            </div>
        </div>

        <div className="foot3 h-full w-11/12 mx-auto">
            <img className='py-4' src={logo} alt="" />
            <div className='h-full w-full flex gap-6 max-sm:flex-col max-sm:gap-1'>
                <p className='text-white w-fit'>© 2024 Elsevier B.V. All rights reserved.</p>
                <div className='text-blue-600 flex gap-4 h-full w-fit max-sm:gap-4 max-sm:w-full max-sm:text-sm'>
                    <a href="https://www.elsevier.com/legal/elsevier-website-terms-and-conditions">Terms & Conditions</a>
                    <a href="https://www.elsevier.com/legal/privacy-policy">Privacy policy</a>
                    <a href="https://www.elsevier.com/about/accessibility">Accessibility</a>
                </div>
            </div>
        </div>


    </footer>
  )
}

export default Footer;