import React from 'react'


const Heroblog = (props) => {


  return (
    <div className="herocard relative h-[500px] w-[400px] rounded-2xl shadow-xl max-sm:h-[400px] max-sm:w-[300px]">
        <div className="heroBlogContent absolute h-full w-full p-10 z-10 flex flex-col items-center justify-between">
                <h1 className='text-4xl font-semibold text-white'>{props.heading}</h1>
                <a className='py-2 px-3 text-lg bg-black text-white tracking-wide' href="#">{props.linkname}</a>
        </div>
        <div className="heroBlogImg absolute top-0 left-0 h-full w-full rounded-3xl overflow-hidden">
            <img className='h-full w-full object-cover' src={props.img} alt="" />
        </div>
    </div>
  )
}

export default Heroblog;