import './App.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Pages/Home';
import Routing from './Routing/Routing';


function App() {
  return (
    // <Routing/>
    <>
      <Header/>
      <Home />
      <Footer />
    </>
  );
}

export default App;
