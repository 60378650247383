import React from 'react'
import Herosection from '../Components/Home/HeroSection/Herosection';
import Section1 from '../Components/Home/Section1/Section1';
import Section2 from '../Components/Home/Section2/Section2';
import Section3 from '../Components/Home/Section3/Section3';
import Section4 from '../Components/Home/Section4/Section4';
import Section5 from '../Components/Home/Section5/Section5';

const Home = () => {
  return (
    <div className='homeSection m-0 p-0 overflow-hidden'>
          <Herosection />
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
    </div>
  )
}

export default Home;