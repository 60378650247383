import React from 'react'
import sec2img from "../../../assests/images/Home/section2/Snippet-Universities-Catalyzing-Social-Change.webp"
import blog1img from "../../../assests/images/Home/section2/Woman_taking_notes.webp"
import blog2img from "../../../assests/images/Home/section2/Snippet-Kamrul-and-robot-hp.webp"
import blog3img from "../../../assests/images/Home/section2/G_ls_n-Sa_lamer-and-Ylann-Schemm.webp"


const Section2 = () => {
    const bottomlink =[
        {
            id:1,
            img:blog1img,
            title:"Librarians share their top tips for managing institutional open access workflows",
            link:"https://www.elsevier.com/connect/librarians-share-their-top-tips-for-managing-institutional-open-access"
        },
        {
            id:2,
            img: blog2img,
            title:"Does minimalism have a place in research?",
            link:"https://www.elsevier.com/connect/does-minimalism-have-a-place-in-research"
        },
        {
            id:3,
            img: blog3img,
            title:"Podcast: University leaders on “breaking the glass ceiling”",
            link:"https://www.elsevier.com/connect/podcast-university-leaders-on-breaking-the-glass-ceiling"
        }
    ]

  return (
    <div className='section2 h-full w-full'>
        <div className="section2Container h-full w-4/5 mx-auto max-sm:w-11/12">
            <div className="section2Head h-full w-full">
                <h1 className='text-4xl py-4 max-sm:tex-3xl max-sm:font-semibold'>Insight of better outcomes</h1>
            </div>
            <div className="section2main py-4 h-[500px] w-full flex 
            max-sm:h-full max-sm:flex-col max-sm:py-0">

                <div className="section2Left relative h-full w-1/2 
                max-sm:w-full">
                    <div className="sec2img h-full w-full">
                        <img className='rounded-3xl max-sm:h-full max-sm:w-full' src={sec2img} alt="" />
                    </div>
                    <div className="sec2cont absolute top-0 left-0 h-full w-2/4 px-6 flex flex-col gap-4 justify-center max-sm:w-full">
                        <h1 className='text-4xl text-white max-sm:text-3xl'>The role of universities in catalyzing social change</h1>
                        <p className='text-white text-lg leading-[1.1]'>How to meet the need for critical and constructive debate</p>
                        <a className='text-white text-lg underline max-sm:text-sm' href="https://www.elsevier.com/connect/the-role-of-universities-in-catalyzing-social-change">Learn more </a>
                    </div>
                </div>

                <div className="section2Right h-full w-1/2 flex flex-col gap-4 max-sm:w-full max-sm:py-4 max-sm:gap-4">
                    {
                        bottomlink.map((item)=>
                            <div className="sec2Blog flex gap-4 h-[30%] w-full pl-10 max-sm:pl-0">
                                <div className="blogImg h-full w-2/5">
                                    <img className='rounded-xl h-full w-full object-cover' src={item.img} alt="" />
                                </div>
                                <div className="blogCont flex flex-col gap-2 w-3/5">
                                    <p className=''>connect</p>
                                    <a href={item.link} className='text-2xl leading-[1.2] max-sm:text-base max-sm:leading-[1.1] hover:underline'>{item.title}</a>
                                </div>
                            </div>
                        )
                    }
                    
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section2