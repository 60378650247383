import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

const Blogslide = (props) => {
  return (
    <div className='h-full w-fit p-6 flex flex-col gap-2 border'>
            <img className='h-[200px] w-full object-cover rounded-2xl' src={props.img} alt="" />
            <p>Connect</p>
            <a href={props.link} className='text-xl leading-[1.1] hover:underline'>{props.heading}</a>
    </div>
  )
}

export default Blogslide;