import React from 'react'
import sec4img from "../../../assests/images/Home/section2/blog1.webp"

const Section4 = () => {
  return (
    <div className='section4 h-full w-full bg-[#fff7ef]'>
        <div className="section4Content h-full w-4/5 py-12 mx-auto flex max-sm:w-11/12 max-sm:flex-col max-sm:gap-6">
            <div className="section4Left w-1/2 flex flex-col justify-center gap-6 max-sm:w-full max-sm:gap-4">
                <h1 className='text-5xl py-4 max-sm:text-3xl max-sm:py-0 max-sm:font-semibold'>Carrers at Elseiver</h1>
                <p className='text-xl w-3/4 leading-[1.1] max-sm:text-base max-sm:leading-[1.1]'>We're building a great workplace culture at Elsevier where amazing people, like you and others, can do purposeful work, grow every day and work with colleagues who care. </p>
                <a className='px-4 py-2 bg-blue-600 w-fit text-white text-lg rounded-xl max-sm:text-base max-sm:my-2' href="https://www.elsevier.com/about/careers">Explore Careers at Elsevier</a>
            </div>
            <div className="section4Right h-full w-1/2 max-sm:w-full">
                <img className='p-4 max-sm:p-0' src={sec4img} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Section4;