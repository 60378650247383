import blog1img from "../../../assests/images/Home/blog/blog1.webp"
import blog2img from "../../../assests/images/Home/blog/blog2.webp"
import blog3img from "../../../assests/images/Home/blog/blog3.webp"
import blog4img from "../../../assests/images/Home/blog/blog4.webp"
import blog5img from "../../../assests/images/Home/blog/blog5.webp"
import blog6img from "../../../assests/images/Home/blog/blog6.webp"


const blogDataList =[{
    id:1,
    img:blog1img,
    heading:"Elsevier inclusion & diversity data snapshot 2024",
    linl:"https://www.elsevier.com/connect/elsevier-inclusion-and-diversity-data-snapshot-2024"
},
{
    id:2,
    img:blog2img,
    heading:"Elsevier named Best Company for Diversity",
    link:"https://www.elsevier.com/connect/elsevier-named-best-company-for-diversity"
},
{
    id:3,
    img:blog3img,
    heading:"Elsevier ranked #2 worldwide for work-life balance",
    link:"https://www.elsevier.com/connect/elsevier-ranked-2-worldwide-for-work-life-balance"
},
{
    id:4,
    img:blog4img,
    heading:"Elsevier CEO Kumsal Bayazit shines as #2 Best CEO for Women",
    link:"https://www.elsevier.com/connect/elsevier-ceo-kumsal-bayazit-shines-as-a-best-ceo-for-women"
},
{
    id:5,
    img:blog5img,
    heading:"Women’s empowerment is a reality at Elsevier — here’s my experience",
    link:"https://www.elsevier.com/connect/womens-empowerment-is-a-reality-at-elsevier-heres-my-experience"
},
{
    id:6,
    img:blog6img,
    heading:"Our journey with rare diseases gives even more meaning to my work at Elsevier",
    link:"https://www.elsevier.com/connect/our-journey-with-rare-diseases-gives-even-more-meaning-to-my-work-at-elsevier"
}
]
export default blogDataList;