import React from 'react'
import Blogswipe from './Blogswipe';

const Section5 = () => {
  return (
    <div className='section5 h-full w-full py-6 max-sm:py-0'>
        <div className="section5Container h-full w-[90%] py-6  ml-[10%] flex flex-col max-sm:ml-0 max-sm:mx-auto">
            <h1 className='text-4xl py-6 max-sm:py-2 max-sm:text-3xl max-sm:px-4'>Discover more about Elseiver</h1>
            <Blogswipe />
        </div>
    </div>
  )
}

export default Section5;