import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import blogSwipeData from "./BlogSwipeData"


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import blogimg1 from "../../../assests/images/Home/section2/Woman_taking_notes.webp"
import Blogslide from './Blogslide';

const Blogswipe = () => {
  const swiperParams = {
    modules: [Navigation, Pagination, Scrollbar, A11y],
    spaceBetween: 10,
    navigation: true,
    onSwiper: (swiper) => console.log(swiper),
    onSlideChange: () => console.log('slide change'),
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: false
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 20,
        navigation:false,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },
  }
  return (
    <div>
    <Swiper {...swiperParams}>
      {
        blogSwipeData.map(item => 
          <SwiperSlide>
            <Blogslide key={item.id} img={item.img} heading={item.heading} link={item.link} />
          </SwiperSlide>
        )
      }
    </Swiper>
    </div>
  )
}

export default Blogswipe;


// modules={[Navigation, Pagination, Scrollbar, A11y]}
//       spaceBetween={10}
//       slidesPerView={4}
//       navigation
//       onSwiper={(swiper) => console.log(swiper)}
//       onSlideChange={() => console.log('slide change')}

