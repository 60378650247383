import React from 'react'
import sec1img from "../../../assests/images/Home/section1/academic-government.webp"

const Section1 = () => {
  return (
    <div className='h-full w-full'>
        <div className="section1Content h-full w-4/5 py-16 mx-auto max-sm:w-11/12 max-sm:py-4">
            <div className="section1Head">
                <h1 className='text-4xl tracking-wide font-light 
                max-sm:text-3xl max-sm:font-semibold max-sm:leading-[1.1]'>Product and services for informed decisions</h1>
            </div>
            <div className="section1Toggle flex gap-8 py-8 max-sm:py-4">
                <button className='text-2xl py-1 tracking-wide border-b-4 border-orange-600 max-sm:text-lg'>Academic & Goverment</button>
                <button className='text-2xl py-1 tracking-wide max-sm:text-lg'>Health</button>
                <button className='text-2xl py-1 tracking-wide max-sm:text-lg'>Industry</button>
            </div>
            <div className="section1Content h-full w-full flex items-center max-sm:flex-col-reverse">
                <div className="section1Left h-full w-1/2 pr-10 flex flex-col gap-4 max-sm:w-full">
                    <h1 className='text-4xl py-4'>Advancing research for the benefit of society</h1>
                    <p className='text-xl leading-[1.1] font-normal'>To support researchers, librarians, research leaders and funders, we curate and enrich research with high-quality data and analytics and help customers address challenges at every stage of the research journey.</p>
                    <p className='text-xl leading-[1.1] font-normal'>From researcher tools to research management and evaluation solutions, learn how we can help you improve productivity and amplify research impact.</p>
                    <a className='py-2 px-4 bg-blue-600 w-fit rounded-lg tracking-wide text-white text-xl my-2' href="https://www.elsevier.com/academic-and-government">Explore Academic & Goverment</a>
                </div>
                <div className="section2Right h-full w-1/2 max-sm:w-full">
                    <img className='p-4 h-full w-full object-cover' src={sec1img} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section1;