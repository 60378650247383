import React from 'react'

const sec3link =[
  {
    id:1,
    name:"Sustainability",
    link:"https://www.elsevier.com/connect/collection/sustainability"
  },
  {
    id:2,
    name:"Open Science",
    link:"https://www.elsevier.com/connect/collection/open-science"
  },
  {
    id:3,
    name:"Gender & Diversity",
    link:"https://www.elsevier.com/connect/collection/gender-diversity"
  },
  {
    id:4,
    name:"Healthcare & Medicine",
    link:"https://www.elsevier.com/connect/collection/healthcare-medicine"
  },
  {
    id:5,
    name:"Reasearch Leadership",
    link:"https://www.elsevier.com/connect/collection/research-leadership"
  },
  {
    id:6,
    name:"Data & Analytics",
    link:"https://www.elsevier.com/connect/collection/data-analytics"
  },
  {
    id:7,
    name:"Researchers",
    link:"https://www.elsevier.com/connect/collection/researchers"
  },
  {
    id:8,
    name:"Librarians",
    link:"https://www.elsevier.com/connect/collection/librarian"
  }
]


const Section3 = () => {
  return (
    <div className='section3 h-full w-full'>
        <div className="section3Cont h-full w-4/5 mx-auto py-8 max-sm:py-2 max-sm:w-11/12">
            <h1 className='text-4xl py-4 tracking-wider max-sm:text-3xl max-sm:font-semibold max-sm:leading-[1.1]'>Featured Topics and communities</h1>
            <div className="section3link h-full w-full py-4 grid grid-cols-4 gap-2 max-sm:grid-cols-1">
                {
                  sec3link.map(item => 
                    <a className='text-lg text-blue-700 py-1 max-sm:py-0 hover:underline' href={item.link}>{item.name}</a>
                  )
                }
            </div>
        </div>
    </div>
  )
}

export default Section3;